import axios from './axios.service';
import config from './../config';
import { getQueryStringFromObject, getRequestHeaders } from './common.service';

async function getFileTypeAnalytics(query=null) {
  try {
    let queryString = '';
    if (query) {
        queryString = '?' + getQueryStringFromObject(query);
    }
    let response;
    try {
        let headers = getRequestHeaders();
        response = await axios.get( config.SERVER_BASE_URL + '/analytics/file_type' + queryString, { headers: headers });
    } catch (error) {
        return error.response.data;
    }
    return response.data;
  } catch (error) {
      return { error: error.message };
  }
}

async function getFileCategoryAnalytics(query=null) {
  try {
    let queryString = '';
    if (query) {
        queryString = '?' + getQueryStringFromObject(query);
    }
    let response;
    try {
        let headers = getRequestHeaders();
        response = await axios.get( config.SERVER_BASE_URL + '/analytics/file_category' + queryString, { headers: headers });
    } catch (error) {
        return error.response.data;
    }
    return response.data;
  } catch (error) {
      return { error: error.message };
  }
}

async function getOrgFileCategoryAnalytics(query=null) {
  try {
    let queryString = '';
    if (query) {
        queryString = '?' + getQueryStringFromObject(query);
    }
    let response;
    try {
        let headers = getRequestHeaders();
        response = await axios.get( config.SERVER_BASE_URL + '/analytics/org/file_category' + queryString, { headers: headers });
    } catch (error) {
        return error.response.data;
    }
    return response.data;
  } catch (error) {
      return { error: error.message };
  }
}

async function getFileSubCategoryAnalytics(query=null) {
  try {
    let queryString = '';
    if (query) {
        queryString = '?' + getQueryStringFromObject(query);
    }
    let response;
    try {
        let headers = getRequestHeaders();
        response = await axios.get( config.SERVER_BASE_URL + '/analytics/file_sub_category' + queryString, { headers: headers });
    } catch (error) {
        return error.response.data;
    }
    return response.data;
  } catch (error) {
      return { error: error.message };
  }
}

async function getOrgFileSubCategoryAnalytics(query=null) {
  try {
    let queryString = '';
    if (query) {
        queryString = '?' + getQueryStringFromObject(query);
    }
    let response;
    try {
        let headers = getRequestHeaders();
        response = await axios.get( config.SERVER_BASE_URL + '/analytics/org/file_sub_category' + queryString, { headers: headers });
    } catch (error) {
        return error.response.data;
    }
    return response.data;
  } catch (error) {
      return { error: error.message };
  }
}

async function getFlaggedAnalytics(query=null) {
  try {
    let queryString = '';
    if (query) {
        queryString = '?' + getQueryStringFromObject(query);
    }
    let response;
    try {
        let headers = getRequestHeaders();
        response = await axios.get( config.SERVER_BASE_URL + '/analytics/flagged' + queryString, { headers: headers });
    } catch (error) {
        return error.response.data;
    }
    return response.data;
  } catch (error) {
      return { error: error.message };
  }
}

async function getOrgFlaggedAnalytics(query=null) {
  try {
    let queryString = '';
    if (query) {
        queryString = '?' + getQueryStringFromObject(query);
    }
    let response;
    try {
        let headers = getRequestHeaders();
        response = await axios.get( config.SERVER_BASE_URL + '/analytics/org/flagged' + queryString, { headers: headers });
    } catch (error) {
        return error.response.data;
    }
    return response.data;
  } catch (error) {
      return { error: error.message };
  }
}

async function getOrganizedAnalytics(query=null) {
  try {
    let queryString = '';
    if (query) {
        queryString = '?' + getQueryStringFromObject(query);
    }
    let response;
    try {
        let headers = getRequestHeaders();
        response = await axios.get( config.SERVER_BASE_URL + '/analytics/organized' + queryString, { headers: headers });
    } catch (error) {
        return error.response.data;
    }
    return response.data;
  } catch (error) {
      return { error: error.message };
  }
}

async function getOrgOrganizedAnalytics(query=null) {
  try {
    let queryString = '';
    if (query) {
        queryString = '?' + getQueryStringFromObject(query);
    }
    let response;
    try {
        let headers = getRequestHeaders();
        response = await axios.get( config.SERVER_BASE_URL + '/analytics/org/organized' + queryString, { headers: headers });
    } catch (error) {
        return error.response.data;
    }
    return response.data;
  } catch (error) {
      return { error: error.message };
  }
}

async function getSizeAnalytics(query=null) {
  try {
    let queryString = '';
    if (query) {
        queryString = '?' + getQueryStringFromObject(query);
    }
    let response;
    try {
        let headers = getRequestHeaders();
        response = await axios.get( config.SERVER_BASE_URL + '/analytics/size' + queryString, { headers: headers });
    } catch (error) {
        return error.response.data;
    }
    return response.data;
  } catch (error) {
      return { error: error.message };
  }
}

async function getOrgSizeAnalytics(query=null) {
  try {
    let queryString = '';
    if (query) {
        queryString = '?' + getQueryStringFromObject(query);
    }
    let response;
    try {
        let headers = getRequestHeaders();
        response = await axios.get( config.SERVER_BASE_URL + '/analytics/org/size' + queryString, { headers: headers });
    } catch (error) {
        return error.response.data;
    }
    return response.data;
  } catch (error) {
      return { error: error.message };
  }
}

export {
  getFileTypeAnalytics,
  getFileCategoryAnalytics,
  getOrgFileCategoryAnalytics,
  getFileSubCategoryAnalytics,
  getOrgFileSubCategoryAnalytics,
  getFlaggedAnalytics,
  getOrgFlaggedAnalytics,
  getOrganizedAnalytics,
  getOrgOrganizedAnalytics,
  getSizeAnalytics,
  getOrgSizeAnalytics
}
