import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, Typography, Grid, Button, TextField, InputAdornment , IconButton, ThemeProvider, Stack, FormControl, InputLabel, Select, MenuItem} from '@mui/material';
import config from 'src/config';
import Iconify from 'src/components/iconify';
import { useNavigate } from 'react-router-dom';
import { register, storeKey, storeToken } from 'src/services/auth.service';
import logo from 'src/assets/images/logo.png';
import { createTheme } from '@mui/material/styles'
import Swal from 'sweetalert2';

const theme = createTheme({
    palette: {
      primary: {
        main: '#ff9933'
      }
    },
});

class RegisterPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            show_password: false,
            show_confirm_password: false,

            pristine: {
                company_name: true,
                username: true,
                password: true,
                confirm_password: true
            },

            // payload
            account_type: 'personal',
            company_name: '',
            username: '',
            password: '',
            confirm_password: ''
        }

    }

    validateCompanyName(company_name, checkPristine = true) {

        if (checkPristine && this.state.pristine.company_name) {
            return true;
        }

        if ((!company_name || company_name == '') && this.state.account_type === 'business') {
            return false;
        } else if ((!company_name || company_name == '') && this.state.account_type === 'personal') {
            return true;
        } 

        return true;
    }

    validateUsername(username, checkPristine = true) {

        if (checkPristine && this.state.pristine.username) {
            return true;
        }

        if (!username) {
            return false;
        }

        if (username.indexOf('@') === -1) {
            return false;
        }

        if (username.indexOf('.') === -1) {
            return false;
        }

        return true;
    }

    validatePassword(password, checkPristine = true) {

        if (checkPristine && this.state.pristine.password) {
            return true;
        }

        if (!password) {
            return false;
        }

        if (password.length < 12) {
            return false;
        }

        return true;
    }

    validateForm() {
        return this.validateUsername(this.state.username, false) && this.validatePassword(this.state.password, false) && (this.state.password == this.state.confirm_password) && this.validateCompanyName(this.state.company_name, false);
    }

    async handleRegister() {
        let payload = {
            account_type: this.state.account_type,
            username: this.state.username,
            password: this.state.password
        };

        if (this.state.account_type === 'business') {
            payload.company_name = this.state.company_name;
        }

        let registerRes = await register(payload);
        if (registerRes.error) {
            console.log(registerRes.error);
            alert('Error: ' + registerRes.error);
        }

        if (registerRes.token) {

            Swal.fire({
                title: 'Success',
                text: 'Account created successfully',
                icon: 'success',
                timer: 2000,
                showConfirmButton: false,
                timerProgressBar: true
            });

            // await storeToken(registerRes.token);
            // await storeKey('username', registerRes?.data?.username);

            // // Redirect to verification page
            // this.props.navigate('/verify');

            // // this.props.navigate('/files');

            this.props.navigate('/login');
        }
    }

    render() {

        return (
            <>
                <Helmet>
                    <title> Register | {config.APP_NAME} </title>
                </Helmet>

                <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '100vh', p: 2 }}>
                    <Grid item xs={12} sm={8} md={5} lg={4}>

                            <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
                                <Typography variant="h4">
                                    Sign up to
                                </Typography>
                                <img src={logo} alt="logo" width={'100px'} height={'auto'} style={{ verticalAlign: 'middle' }} />
                            </Stack>

                            <ThemeProvider theme={theme}>

                                <FormControl fullWidth sx={{ mb: 2 }}>
                                    <InputLabel id="account-type-select-label">Account Type</InputLabel>
                                    <Select
                                        labelId="account-type-select-label"
                                        id="account-type-select"
                                        value={this.state.account_type}
                                        label="Account Type"
                                        onChange={(e) => {
                                            this.setState({
                                                account_type: e.target.value
                                            });
                                        }}
                                    >
                                        <MenuItem value={'personal'}>Personal</MenuItem>
                                        <MenuItem value={'business'}>Business</MenuItem>
                                    </Select>
                                </FormControl>

                                {(this.state.account_type === 'business') ? (
                                    <TextField
                                        fullWidth
                                        name="company_name"
                                        label="Company Name"
                                        sx={{ mb: 2 }}
                                        value={this.state.company_name}
                                        onChange={(e) => {
                                            this.setState({
                                                company_name: e.target.value,
                                                pristine: {
                                                    ...this.state.pristine,
                                                    company_name: false
                                                }
                                            });
                                        }}
                                        error={!this.validateCompanyName(this.state.company_name)}
                                        helperText={!this.validateCompanyName(this.state.company_name) ? 'Invalid Company Name' : ''}
                                    />
                                ) : null}


                                <TextField
                                    fullWidth
                                    name="email"
                                    label="Email address"
                                    sx={{ mb: 2 }}
                                    value={this.state.username}
                                    onChange={(e) => {
                                        this.setState({
                                            username: e.target.value,
                                            pristine: {
                                                ...this.state.pristine,
                                                username: false
                                            }
                                        });
                                    }}
                                    error={!this.validateUsername(this.state.username)}
                                    helperText={!this.validateUsername(this.state.username) ? 'Invalid email address' : ''}
                                />

                                <TextField
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    sx={{ mb: 2 }}
                                    type={this.state.show_password ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => {
                                                        this.setState({ show_password: !this.state.show_password });
                                                    }}
                                                    edge="end"
                                                >
                                                    <Iconify icon={this.state.show_password ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    value={this.state.password}
                                    onChange={(e) => {
                                        this.setState({
                                            password: e.target.value,
                                            pristine: {
                                                ...this.state.pristine,
                                                password: false
                                            }
                                        });
                                    }}
                                    error={!this.validatePassword(this.state.password)}
                                    helperText={!this.validatePassword(this.state.password) ? 'Password must be at least 12 characters' : ''}
                                />
                                
                                <TextField
                                    fullWidth
                                    name="confirm_password"
                                    label="Confirm Password"
                                    sx={{ mb: 2 }}
                                    type={this.state.show_confirm_password ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => {
                                                        this.setState({ show_confirm_password: !this.state.show_confirm_password });
                                                    }}
                                                    edge="end"
                                                >
                                                    <Iconify icon={this.state.show_confirm_password ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    value={this.state.confirm_password}
                                    onChange={(e) => {
                                        this.setState({ confirm_password: e.target.value });
                                    }}
                                    error={this.state.password !== this.state.confirm_password}
                                    helperText={this.state.password !== this.state.confirm_password ? 'Passwords do not match' : ''}
                                />

                                <Typography variant="body2" sx={{ mb: 2 }}>
                                    Already have an account? {' '}
                                    <Link
                                        variant="subtitle2"
                                        underline="hover"
                                        sx={{ cursor: 'pointer'}}
                                        onClick={() => {
                                            this.props.navigate('/login');
                                        }}
                                    >
                                        Login
                                    </Link>
                                </Typography>

                                <Button
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                    onClick={this.handleRegister.bind(this)}
                                    disabled={!this.validateForm()}
                                    style={{ color: 'white' }}
                                >
                                    Register
                                </Button>
                            </ThemeProvider>

                    </Grid>
                </Grid>
            </>
        )
    }
}

export default function (props) {
    const navigate = useNavigate();

    return <RegisterPage {...props} navigate={navigate} />;
}

