import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Grid, Tab, Typography } from '@mui/material';
import config from 'src/config';
import Swal from 'sweetalert2';
import showLoading from './../common/loading';
import { getFiles } from '../services/file.service';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactWebStore } from 'react-webstore';
import DropboxStatusCheckAndConnect from 'src/components/cloud_services/dropbox/DropboxStatusCheckAndConnect';
import OnedriveStatusCheckAndConnect from 'src/components/cloud_services/onedrive/OnedriveStatusCheckAndConnect';
import GoogleDriveStatusCheckAndConnect from 'src/components/cloud_services/googledrive/GoogleDriveStatusCheckAndConnect';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { getAvailableStorageServices } from 'src/services/storage.service';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Duplicates from 'src/components/declutter/duplicates';

// ----------------------------------------------------------------------

const flags = [
	{ value: 'attachment', label: 'Attachment', color: 'purple' },
	{ value: 'important', label: 'Important', color: 'red' },
	{ value: 'in_house', label: 'In house', color: 'blue' },
	{ value: 'temp', label: 'Temp', color: 'black' },
	{ value: 'wip', label: 'WIP', color: 'orange' },
	{ value: 'complete', label: 'Complete', color: 'green' },
	{ value: 'final', label: 'Final', color: 'red'},
	{ value: 'archive', label: 'Archive', color: 'grey'},
]

// Debounce function
function debounce(func, wait) {
    let timeout;
    return function(...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
    };
}

class Declutter extends React.Component {
  

	constructor(props) {
		super(props);

		this.state = {

            available_storage_services: [],

            current_tab: 'duplicate',
            
            files: [],
            file_containers: [],
            search: '',
            limit: 12,
            skip: 0,
            total_files: 0,
            total_size: 0,
            flags: [],
            labels: [],
            containers: [],
            file_ids: [],
            flagged: false,
            labelled: false,
            unorganized: true,
            organized: false,
            recent: true,
            recent_type: 'last_week',
            service: [],
            extension: [],
            advanced_search: false,
            show_selection: false,
            selected: {
                files: [],
                total_size: 0
            },
            select_all: false,
            addLabelModal: false,
            addFlagModal: false
        };
	}


	// promised setState
	setStateAsync(state) {
		return new Promise((resolve) => {
			this.setState(state, resolve)
		});
	}

    async componentDidMount() {
        let available_storage_services = await getAvailableStorageServices();
        if (!available_storage_services.error) {
            this.setState({
                available_storage_services: available_storage_services.services
            })
        }
    }

  render() {
	return (
	  <>
		<Helmet>
		  <title> Declutter | {config.APP_NAME} </title>
		</Helmet>

        <Grid container spacing={2} mb={1} sx={{p: 0, justifyContent: 'space-between'}}>
		  <Grid item xs={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
			  <Typography variant="h4">
				 Declutter
			  </Typography>
              <Grid display={'flex'} flexDirection={'row'}>
                {(this.state.available_storage_services.includes('onedrive')) && (
                    <OnedriveStatusCheckAndConnect
                        hideUnsyncButton={true}
                        minimal={true}
                        callbackUrl={process.env.REACT_APP_ONEDRIVE_DASHBOARD_CALLBACK_URL}
                    />
                )}
                {(this.state.available_storage_services.includes('dropbox')) && (
                    <DropboxStatusCheckAndConnect
                        hideUnsyncButton={true}
                        minimal={true}
                        callbackUrl={process.env.REACT_APP_DROPBOX_DASHBOARD_CALLBACK_URL}
                    />
                )}
                {(this.state.available_storage_services.includes('googledrive')) && (
                    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLEDRIVE_CLIENT_ID}>
                        <GoogleDriveStatusCheckAndConnect
                            hideUnsyncButton={true}
                            minimal={true}
                            callbackUrl={process.env.REACT_APP_GOOGLEDRIVE_DASHBOARD_CALLBACK_URL}
                        />
                    </GoogleOAuthProvider>
                )}
              </Grid>
		  </Grid>
		</Grid>

        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={this.state.current_tab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={(e, value) => {
                        this.setState({current_tab: value})
                    }}>
                        <Tab label="Duplicates" value="duplicate" />
                    </TabList>
                </Box>
                <TabPanel value="duplicate">
                    <Duplicates />
                </TabPanel>
            </TabContext>
        </Box>

	  </>
	);
  }
}

// Wrap and export
export default function (props) {
    const navigate = useNavigate();
    const params = useParams();

    return <Declutter {...props} navigate={navigate} params={params} />;
}
