import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Backdrop, Button, Card, CardContent, Chip, FormControl, Grid, IconButton, InputLabel, MenuItem, Modal, Pagination, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import config from 'src/config';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import showLoading from 'src/common/loading';
import { inviteUser } from 'src/services/user.service';
import { addCompany } from 'src/services/company.service';

// ----------------------------------------------------------------------



class AddEditCompanyModal extends React.Component {

	constructor(props) {
		super(props);

		this.state = {

			role: localStorage.getItem('role') || config.USER_ROLES.USER,

			company: null,
			company_name: '',

			companies: [],

			invite_email: '',
			invite_role: null,

			users: [],
			search: '',
			limit: 10,
			skip: 0,
			total_users: 0,
		}
	}

	// promised setState
	setStateAsync(state) {
		return new Promise((resolve) => {
			this.setState(state, resolve)
		});
	}

	async componentDidMount() {
		
	}


	async addCompany() {
		showLoading(true);
		let addCompanyRes = await addCompany({
			name: this.state.company_name
		});
		if (addCompanyRes.error) {
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: addCompanyRes.error
			});
		} else {
			Swal.fire({
				icon: 'success',
				title: 'Success',
				text: 'Company added successfully'
			});
			this.setState({
				company_name: ''
			});
			if (this.props.onSave)
				this.props.onSave();
		}
	}

	async editCompany() {

	}

	async saveCompany() {
		if (this.state.company) {
			await this.editCompany();
		} else {
			await this.addCompany();
		}
	}

	render() {
		return (
			<Modal
				open={this.props.open}
				onClose={() => {

					if (this.props.onClose)
						this.props.onClose();
				}}
			>
				<Grid container>
					<Grid item style={modalStyle} xs={12} md={6} lg={4}>
						<Card variant='outlined'>
							<CardContent style={{ padding: 10 }}>
								<Stack direction="column" spacing={2} alignItems="center">
									<TextField
										size='small'
										label={'Company Name'}
										variant="outlined"
										fullWidth
										value={this.state.company_name}
										onChange={async (e) => {
											await this.setStateAsync({
												company_name: e.target.value
											});
										}}
									/>
									<Button
										variant='contained'
										fullWidth
										color='primary'
										onClick={async () => {
											this.saveCompany();
										}}
									>
										Save
									</Button>
								</Stack>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Modal>
		);
	}
}

const modalStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '100%',
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 2,
	maxHeight: '90%',
	overflowY: 'auto'
};

export default function (props) {
	const navigate = useNavigate();

	return <AddEditCompanyModal {...props} navigate={navigate} />;
}
