import axios from './axios.service';
import config from './../config';
import { getQueryStringFromObject, getRequestHeaders } from './common.service';

async function suggestContainers(payload) {
    let response;
    try {
        let headers = getRequestHeaders();
        response = await axios.post( config.SERVER_BASE_URL + '/files/containers/suggest', payload, { headers: headers });
    } catch (error) {
        return error.response.data;
    }
    return response.data;
}

export {
    suggestContainers
}
