import axios from './axios.service';
import config from './../config';
import { getRequestHeaders } from './common.service';

const getAvailableStorageServices = async () => {
    try {
        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/services/available', {
                headers: getRequestHeaders()
            });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const updateStorageEmail = async (storage, email) => {
    try {
        let response;
        try {
            response = await axios.post( config.SERVER_BASE_URL + '/services/' + storage + '/update_email', { email }, {
                headers: getRequestHeaders()
            });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

export {
    getAvailableStorageServices,
    updateStorageEmail
}
