import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FileTypeAnalytics from 'src/components/dashboard/FileTypeAnalytics';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Autocomplete, Box, Tab, TextField } from '@mui/material';
import FlaggedAnalytics from 'src/components/dashboard/FlaggedAnalytics';
import ProjFileTypeAnalytics from 'src/components/dashboard/ProjFileTypeAnalytics';
import ProjFlaggedAnalytics from 'src/components/dashboard/ProjFlaggedAnalytics';
import TeamFileTypeAnalytics from 'src/components/dashboard/TeamFileTypeAnalytics';
import TeamFlaggedAnalytics from 'src/components/dashboard/TeamFlaggedAnalytics';
import DeptFlaggedAnalytics from 'src/components/dashboard/DeptFlaggedAnalytics';
import DeptFileTypeAnalytics from 'src/components/dashboard/DeptFileTypeAnalytics';
import OrgFlaggedAnalytics from 'src/components/dashboard/OrgFlaggedAnalytics';
import OrgFileTypeAnalytics from 'src/components/dashboard/OrgFileTypeAnalytics';
import OrgDeptWiseAnalytics from 'src/components/dashboard/OrgDeptWiseAnalytics';
import OrganizedAnalytics from 'src/components/dashboard/OrganizedAnalytics';
import SizeAnalytics from 'src/components/dashboard/SizeAnalytics';
import FileCategoryAnalytics from 'src/components/dashboard/FileCategoryAnalytics';
import FileSubCategoryAnalytics from 'src/components/dashboard/FileSubCategoryAnalytics';
import OrgSizeAnalytics from 'src/components/dashboard/OrgSizeAnalytics';
import OrgOrganizedAnalytics from 'src/components/dashboard/OrgOrganizedAnalytics';
import OrgFileCategoryAnalytics from 'src/components/dashboard/OrgFileCategoryAnalytics';
import OrgFileSubCategoryAnalytics from 'src/components/dashboard/OrgFileSubCategoryAnalytics';
import { getUsers } from 'src/services/user.service';
import config from 'src/config';

class Dashboard extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            role: localStorage.getItem('role') || config.USER_ROLES.USER,
            value: 'mine',
            users: [],
            selected_user: null
        };
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    async setUsers() {
        let users = await getUsers({minimal: true});
        if (users.error) {
            console.error(users.error);
            return;
        }
        users = users.users;

        users = users.map(user => {
            return {
                label: user.username,
                id: String(user._id)
            }
        });

        this.setState({
            users: users
        });
    }

    componentDidMount() {
        this.setUsers();
    }

    render() {
        return (
            <>

                <TabContext value={this.state.value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={(e, value) => {
                            this.setState({ value });
                        }} aria-label="lab API tabs example">
                            <Tab label="Mine" value="mine" />
                            {(this.state.role != config.USER_ROLES.USER) ? (
                                <Tab label="Team" value="team" />
                            ) : null}
                            {(this.state.role != config.USER_ROLES.USER) ? (
                                <Tab label="Organization" value="org" />
                            ) : null}
                            {(this.state.role != config.USER_ROLES.USER) ? (
                                <Tab label="Digital KPI" value="digital_kpi" />
                            ) : null}
                        </TabList>
                    </Box>
                    <TabPanel value="mine">
                        <SizeAnalytics />
                        <br />
                        <OrganizedAnalytics />
                        <br />
                        {/* <FileTypeAnalytics />
                        <br /> */}
                        <FileCategoryAnalytics />
                        <br />
                        <FileSubCategoryAnalytics />
                        <br />
                        <FlaggedAnalytics />
                    </TabPanel>
                    <TabPanel value="proj">
                        <ProjFileTypeAnalytics />
                        <br />
                        <ProjFlaggedAnalytics />
                    </TabPanel>
                    <TabPanel value="team">

                        <Autocomplete
                            disablePortal
                            options={this.state.users}
                            renderInput={(params) => <TextField {...params} label="Team Member" />}
                            onChange={async (e, value) => {
                                await this.setStateAsync({ selected_user: null });
                                this.setState({
                                    selected_user: value
                                });
                            }}
                        />
                        <br />

                        {(this.state.selected_user) ? (
                            <>
                                <SizeAnalytics
                                    user_id={this?.state?.selected_user?.id}
                                />
                                <br />
                                <OrganizedAnalytics
                                    user_id={this?.state?.selected_user?.id}
                                />
                                <br />
                                <FileCategoryAnalytics
                                    user_id={this?.state?.selected_user?.id}
                                />
                                <br />
                                <FileSubCategoryAnalytics
                                    user_id={this?.state?.selected_user?.id}
                                />
                                <br />
                                <FlaggedAnalytics
                                    user_id={this?.state?.selected_user?.id}
                                />
                            </>
                        ) : null}
                    </TabPanel>
                    <TabPanel value="dept">
                        <DeptFileTypeAnalytics />
                        <br />
                        <DeptFlaggedAnalytics />
                    </TabPanel>
                    <TabPanel value="org">
                        <OrgSizeAnalytics />
                        <br />
                        <OrgOrganizedAnalytics />
                        <br />
                        <OrgFileCategoryAnalytics />
                        <br />
                        <OrgFileSubCategoryAnalytics />
                        <br />
                        <OrgFlaggedAnalytics />
                        {/* <OrgDeptWiseAnalytics />
                        <br />
                        <OrgFileTypeAnalytics />
                        <br />
                        <OrgFlaggedAnalytics /> */}
                    </TabPanel>
                    <TabPanel value="digital_kpi">
                        {/* Coming soon */}
                        <div style={{ textAlign: 'center' }}>
                            <h1>Coming soon...</h1>
                        </div>
                    </TabPanel>
                </TabContext>
            </>
        );
    }
}

// Wrap and export
export default function (props) {
    const navigate = useNavigate();
    const params = useParams();

    return <Dashboard {...props} navigate={navigate} params={params} />;
}
