import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import LabelIcon from '@mui/icons-material/Label';
import AllInboxIcon from '@mui/icons-material/AllInbox';

import Swal from "sweetalert2";

import { Autocomplete, Backdrop, Button, Card, Checkbox, Chip, Fade, FormControlLabel, FormGroup, Grid, Modal, Stack, TextField, Typography, createFilterOptions } from "@mui/material";

import config from "../config";
import { updateFile, getFile, generateLabels } from '../services/file.service';
import SyncIcon from '@mui/icons-material/Sync';
import showLoading from "src/common/loading";
import moment from "moment";
import OnedriveStatusCheckAndConnect from "src/components/cloud_services/onedrive/OnedriveStatusCheckAndConnect";
import DropboxStatusCheckAndConnect from "src/components/cloud_services/dropbox/DropboxStatusCheckAndConnect";
import GoogleDriveStatusCheckAndConnect from "src/components/cloud_services/googledrive/GoogleDriveStatusCheckAndConnect";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { suggestLabels } from "src/services/label.service";
import ArchiveIcon from '@mui/icons-material/Archive';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import BusinessIcon from '@mui/icons-material/Business';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import StarsIcon from '@mui/icons-material/Stars';
import { suggestContainers } from "src/services/container.service";

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

const flags = [
	{ value: 'attachment', label: 'Attachment', color: 'purple', icon: <AttachFileIcon style={{fontSize: '18px', color: 'purple'}} /> },
	{ value: 'important', label: 'Important', color: 'red', icon: <StarsIcon style={{fontSize: '18px', color: 'red'}} /> },
	{ value: 'in_house', label: 'In house', color: 'blue', icon: <BusinessIcon style={{fontSize: '18px', color: 'blue'}} /> },
	{ value: 'temp', label: 'Temp', color: 'black', icon: <RunningWithErrorsIcon style={{fontSize: '18px', color: 'black'}} /> },
	{ value: 'wip', label: 'WIP', color: 'orange', icon: <WorkHistoryIcon style={{fontSize: '18px', color: 'orange'}} /> },
	{ value: 'complete', label: 'Complete', color: 'green', icon: <DoneIcon style={{fontSize: '18px', color: 'green'}} /> },
	{ value: 'final', label: 'Final', color: 'red', icon: <DoneAllIcon style={{fontSize: '18px', color: 'red'}} /> },
	{ value: 'archive', label: 'Archive', color: 'grey', icon: <ArchiveIcon style={{fontSize: '18px', color: 'grey'}} /> },
	{ value: 'duplicate', label: 'Duplicate', color: 'brown', icon: <ContentCopyIcon style={{fontSize: '18px', color: 'brown'}} /> },
]

class AddEditFileModal extends React.Component {

	constructor(props) {
		super(props);
		this.state = {

			mode: 'edit',
			file_id: props?.file?._id,

			file: null,

			name: '',
			generated_names: [],
			flags: [],
			labels: [],
			containers: [],
			label_suggestions: [],
			container_suggestions: [],

			loading: false,
			generating_labels: false,
			generating_name: false,
			error: {},
		}
	}

	// promised setState
	setStateAsync(state) {
		return new Promise((resolve) => {
			this.setState(state, resolve)
		});
	}

	async componentDidMount() {
		try {

			showLoading(true);

			let file = await getFile(this.state.file_id)

			if (file.error) {

				showLoading(false);

				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: file.error,
					confirmButtonText: 'Ok',
					confirmButtonColor: config.primaryColor
				});
				return;
			}


			file = file.file
			this.setState({
				file: file,
				name: file.name,
				flags: file?.flags ? file.flags : [],
				labels: file?.labels ? file.labels : [],
				containers: file?.containers ? file.containers : []
			})
			showLoading(false);

		} catch (error) {
			showLoading(false);
			console.error("Error fetching file details:", error)
		}
	}

	saveFile = async () => {

		this.setState({
			loading: true
		});
		showLoading(true);

		const payload = {
			name: this.state.name,
			flags: this.state.flags,
			labels: this.state.labels,
			containers: this.state.containers,
			organized: true
		}

		let save_file_response = await updateFile(this.state.file_id, payload);
		
		if (save_file_response.error) {

			showLoading(false);

			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: save_file_response.error,
				confirmButtonText: 'Ok',
				confirmButtonColor: config.primaryColor
			}).then(() => {
				this.props.onSave();
			});
			this.setState({
				loading: false
			});
			return;
		}

		showLoading(false);
		this.setState({
			loading: false
		});

		Swal.fire({
			icon: 'success',
			title: 'Success',
			text: 'Saved successfully',
			confirmButtonText: 'Ok',
			confirmButtonColor: config.primaryColor
		});
		this.props.onSave();
	}

	generateLabels = async () => {
		try {

			await this.setStateAsync({
				generating_labels: true
			});

			let labels_response = await generateLabels(this.state.file_id);
			if (labels_response.error) {
				console.log("Error generating labels:", labels_response.error);
				alert("Error generating labels - " + labels_response.error)
				await this.setStateAsync({
					generating_labels: false
				});
				return;
			}

			labels_response = labels_response.labels;
			labels_response = JSON.parse(labels_response);

			console.log("labels_response", labels_response)

			let labels = this.state.labels;
			labels = labels.concat(labels_response.Keywords);

			// unique labels
			labels = [...new Set(labels)];

			await this.setStateAsync({
				generating_labels: false,
				labels: labels
			});
		} catch (error) {
			await this.setStateAsync({
				generating_labels: false
			});
			alert("Unknown error occurred while generating labels - " + error.message)
			console.log("Unknown error occurred while generating labels - ", error.message)
		}
	}

	generateNames = async () => {
		try {

			await this.setStateAsync({
				generating_name: true
			});

			let file = this.state.file;

			let file_name_without_extension = file.name.split('.').slice(0, -1).join('.');
			// sometimes files have no extension ( like google docs )
			if (!file_name_without_extension) {
				file_name_without_extension = file.name;
				file.extension = '';
			}


			// first 3 labels
			let first_3_labels = (this.state.labels && this.state.labels.length > 3) ? this.state.labels.slice(0, 3) : (this.state.labels ? this.state.labels : []);
			let generated_file_name = file_name_without_extension + (first_3_labels.length ? '_' + first_3_labels.join('_') : '') + (file.real_modified_at ? '_' + moment(file.real_modified_at).format("YYYYMMDD") : '') + (file.extension ? ('.' + file.extension) : '');
			let generated_file_name_2 = (first_3_labels.length ? first_3_labels.join('_') : '') + (file.real_modified_at ? '_' + moment(file.real_modified_at).format("YYYYMMDD") : '') + (file.extension ? ('.' + file.extension) : '');
			let generated_file_name_3 = file_name_without_extension + (file.real_modified_at ? '_' + moment(file.real_modified_at).format("YYYYMMDD") : '') + (file.extension ? ('.' + file.extension) : '');

			let generated_names = [];
			generated_names.push(generated_file_name);
			generated_names.push(generated_file_name_2);
			generated_names.push(generated_file_name_3);

			generated_names = [...new Set(generated_names)];

			await this.setStateAsync({
				generated_names: generated_names,
				generating_name: false
			});

		} catch (error) {
			await this.setStateAsync({
				generating_name: false,
				generated_names: []
			});
			alert("Unknown error occurred while generating name - " + error.message)
			console.log("Unknown error occurred while generating name - ", error.message)
		}
	}

	updateLabelSuggestions = async (e) => {

		let query = e.target.value;

		let label_suggestions = await suggestLabels({ query });

		this.setState({
			label_suggestions: label_suggestions?.next_labels ? label_suggestions.next_labels : []
		});
	}

	updateContainerSuggestions = async (e) => {
		
		let query = e.target.value;

		let container_suggestions = await suggestContainers({ query });

		console.log("container_suggestions", container_suggestions)

		this.setState({
			container_suggestions: container_suggestions?.next_containers ? container_suggestions.next_containers : []
		});
	}

	render() {
		return (
			<div>
				<Modal
					aria-labelledby="transition-modal-title"
					aria-describedby="transition-modal-description"
					open={this.props.open}
					onClose={() => {

						if (this.props.onClose)
							this.props.onClose();
					}}
					closeAfterTransition
					slots={{ backdrop: Backdrop }}
					slotProps={{
						backdrop: {
							timeout: 500,
						},
					}}
				>
					<Fade in={this.props.open}>
						<Card sx={modalStyle}>
							<Grid container spacing={2} mb={2} sx={{ p: 1 }}>
								<Grid item xs={12}>
									<Stack spacing={1} direction={'row'}>
										<Grid display={'flex'} flexDirection={'row'}>
											{(this.state?.file?.service == config.FILE_SERVICES.ONEDRIVE) && (
												<OnedriveStatusCheckAndConnect
													hideUnsyncButton={true}
													minimal={true}
													callbackUrl={process.env.REACT_APP_ONEDRIVE_DASHBOARD_CALLBACK_URL}
												/>
											)}
											{(this.state?.file?.service == config.FILE_SERVICES.DROPBOX) && (
												<DropboxStatusCheckAndConnect
													hideUnsyncButton={true}
													minimal={true}
													callbackUrl={process.env.REACT_APP_DROPBOX_DASHBOARD_CALLBACK_URL}
												/>
											)}
											{(this.state?.file?.service == config.FILE_SERVICES.GOOGLEDRIVE) && (
												<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLEDRIVE_CLIENT_ID}>
													<GoogleDriveStatusCheckAndConnect
														hideUnsyncButton={true}
														minimal={true}
														callbackUrl={process.env.REACT_APP_GOOGLEDRIVE_DASHBOARD_CALLBACK_URL}
													/>
												</GoogleOAuthProvider>
											)}
										</Grid>
										<TextField
											label={'Name'}
											variant="outlined"
											value={this.state.name}
											onChange={async (e) => {
												await this.setState({
													name: e.target.value
												});
											}}
											sx={{width: '100%'}}
											size="small"
										/>
										<Button variant="outlined" size="small"
											// full width same as the text inside
											sx={{textTransform: 'none', width: '100%', maxWidth: 'fit-content'}}
											disabled={this.state.generating_name}
											onClick={this.generateNames}
											startIcon={
												(this.state.generating_name) ? (
													<SyncIcon style={{color: 'gray', animation: 'spin 2s linear infinite reverse'}} />
												) : null
											}
										>
											{(this.state.generating_name) ? ' Generating...' : 'Suggest name'}
										</Button>
									</Stack>
									{this.state.generated_names.map((generated_name, index) => {
										return (
											<Chip
												label={
													<span>
														Click to use this name : <strong>{generated_name}</strong>
													</span>
												}
												variant="outlined"
												onClick={() => {
													this.setState({
														name: generated_name,
														generated_names: []
													});
												}}
												onDelete={() => {
													let generated_names = this.state.generated_names;
													generated_names.splice(index, 1);
													this.setState({
														generated_names: generated_names
													});
												}}
												sx={{m: 1}}
											/>
										)
									})}
								</Grid>
								<Grid item xs={12}>
									<Stack spacing={2}>
										<FormGroup sx={{flexDirection: 'row'}} >
											{flags.map((flag, index) => {
												return (

													<div style={{marginRight: '10px'}}>

													<FormControlLabel
														key={index}
														control={
															<Checkbox
																checked={this.state.flags.includes(flag.value)}
																sx={{
																	color: flag.color,
																	'&.Mui-checked': {
																		color: flag.color,
																	},
																}}
																icon={flag.icon}
																checkedIcon={flag.icon}
															/>
														}
														onChange={(e) => {
															if (e.target.checked) {
																this.setState({
																	flags: [...this.state.flags, flag.value]
																});
															} else {
																this.setState({
																	flags: this.state.flags.filter(f => f !== flag.value)
																});
															}
														}}
														label={flag.label}
														style={{marginRight: '0px'}}
													/>

													<Checkbox
														key={index}
														checked={this.state.flags.includes(flag.value)}
														sx={{
															color: flag.color,
															'&.Mui-checked': {
																color: flag.color,
															}
														}}
														onChange={(e) => {
															if (e.target.checked) {
																this.setState({
																	flags: [...this.state.flags, flag.value]
																});
															} else {
																this.setState({
																	flags: this.state.flags.filter(f => f !== flag.value)
																});
															}
														}}
													/>

													</div>
												)
											})}
										</FormGroup>
									</Stack>
								</Grid>
								<Grid item xs={12}>
									<Stack spacing={1} direction={'row'}>
										<Autocomplete
											freeSolo
											multiple
											id="labels-outlined"
											options={this.state.label_suggestions}
											value={this.state.labels}
											onChange={(event, newValue) => {
												this.setState({
													labels: newValue
												});
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="outlined"
													label="Labels"
													placeholder="What is it ?"
													InputProps={{
														...params.InputProps,
														endAdornment: (
															<React.Fragment>
																{params.InputProps.endAdornment}
																<LabelIcon />
															</React.Fragment>
														)
													}}
													onChange={this.updateLabelSuggestions}
												/>
											)}
											sx={{ width: '100%' }}
											size="small"
										/>
										<Button variant="outlined" size="small"
											sx={{textTransform: 'none', width: '100%', maxWidth: 'fit-content'}}
											disabled={this.state.generating_labels}
											onClick={this.generateLabels}
											startIcon={
												(this.state.generating_labels) ? (
													<SyncIcon style={{color: 'gray', animation: 'spin 2s linear infinite reverse'}} />
												) : null
											}
										>
											{(this.state.generating_labels) ? ' Generating labels...' : 'Generate labels'}
										</Button>
									</Stack>
								</Grid>
								<Grid item xs={12}>

									<Stack spacing={1} direction={'row'} justifyContent={'space-between'}>
									<Autocomplete
											freeSolo
											multiple
											id="containers-outlined"
											options={this.state.container_suggestions}
											value={this.state.containers}
											onChange={(event, newValue) => {
												this.setState({
													containers: newValue
												});
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="outlined"
													label="Containers"
													placeholder="Where it belongs to ?"
													InputProps={{
														...params.InputProps,
														endAdornment: (
															<React.Fragment>
																{params.InputProps.endAdornment}
																<AllInboxIcon />
															</React.Fragment>
														)
													}}
													onChange={this.updateContainerSuggestions}
												/>
											)}
											sx={{ width: '100%' }}
											size="small"
										/>
									</Stack>
								</Grid>

								{/* Shared Users */}
								{((this.state.file?.owners && this.state.file?.owners.length > 0) || (this.state.file?.owner_emails && this.state.file?.owner_emails.length > 0)) ? (
									<Grid item xs={12}>
										<Stack spacing={1} direction={'row'}>
											{/* Icon */}
											<AdminPanelSettingsIcon />
											{/* Owners list */}
											{(this.state.file?.owners && this.state.file?.owners.length > 0) ? (
												// Chip fields
												<Stack spacing={1} direction={'row'}>
													{this.state.file?.owners.map((owner, index) => {
														return (
															<Chip
																size="small"
																key={index}
																label={owner?.name ? (owner?.name + ' (' + owner?.username + ')') : owner?.username}
																variant="outlined"
																sx={{m: 1, borderRadius: '5px'}}
															/>
														)
													})}
												</Stack>
											) : null}
											{(this.state.file?.owner_emails && this.state.file?.owner_emails.length > 0) ? (
												// Chip fields
												<Stack spacing={1} direction={'row'}>
													{this.state.file?.owner_emails.map((owner_email, index) => {
														return (
															<Chip
																size="small"
																key={index}
																label={owner_email}
																variant="outlined"
																sx={{m: 1, borderRadius: '5px'}}
															/>
														)
													})}
												</Stack>
											) : null}
										</Stack>
									</Grid>
								) : null}

								{/* Shared Users */}
								{((this.state.file?.shared_users && this.state.file?.shared_users.length > 0) || (this.state.file?.shared_user_emails && this.state.file?.shared_user_emails.length > 0)) ? (
									<Grid item xs={12}>
										<Stack spacing={1} direction={'row'}>
											{/* Icon */}
											<PeopleAltIcon />
											{/* Owners list */}
											{(this.state.file?.shared_users && this.state.file?.shared_users.length > 0) ? (
												// Chip fields
												<Stack spacing={1} direction={'row'}>
													{this.state.file?.shared_users.map((shared_user, index) => {
														return (
															<Chip
																size="small"
																key={index}
																label={shared_user?.name ? (shared_user?.name + ' (' + shared_user?.username + ')') : shared_user?.username}
																variant="outlined"
																sx={{m: 1, borderRadius: '5px'}}
															/>
														)
													})}
												</Stack>
											) : null}
											{(this.state.file?.shared_user_emails && this.state.file?.shared_user_emails.length > 0) ? (
												// Chip fields
												<Stack spacing={1} direction={'row'}>
													{this.state.file?.shared_user_emails.map((shared_user_email, index) => {
														return (
															<Chip
																size="small"
																key={index}
																label={shared_user_email}
																variant="outlined"
																sx={{m: 1, borderRadius: '5px'}}
															/>
														)
													})}
												</Stack>
											) : null}
										</Stack>
									</Grid>
								) : null}

							</Grid>

							<Grid container spacing={2} mb={2} sx={{ p: 1, justifyContent: 'space-around' }}>
								<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
									<Button
										disableElevation
										fullWidth
										variant="contained"
										onClick={this.saveFile}
										disabled={this.state.loading}
										size="small"
									>
										{this.state.loading ? 'Organizing...' : 'Organize'}
									</Button>
								</Grid>
							</Grid>


						</Card>
					</Fade>
				</Modal>
			</div>
		);
	}
}

const modalStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '80%',
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 2,
	maxHeight: '90%',
	overflowY: 'auto'
};

// Wrap and export
export default function (props) {
	const params = useParams();
	const navigate = useNavigate();
	return <AddEditFileModal {...props} navigate={navigate} params={params} />;
}