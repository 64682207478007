import axios from './axios.service';
import config from './../config';
const { getRequestHeaders } = require("./common.service");


let cancelGetSearchSuggestionsTokenSource;
async function getSearchSuggestions(payload) {
    
    if (cancelGetSearchSuggestionsTokenSource) {
        cancelGetSearchSuggestionsTokenSource.cancel('Operation canceled due to new request.');
    }
    cancelGetSearchSuggestionsTokenSource = axios.CancelToken.source();

    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.post( config.SERVER_BASE_URL + '/files/search/suggest', payload, {
                headers: headers,
                cancelToken: cancelGetSearchSuggestionsTokenSource.token
            });
        } catch (error) {

            console.log('error', error);
            if (axios.isCancel(error)) {
                return;
            }

            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

export {
    getSearchSuggestions
}
