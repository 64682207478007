import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Button, Card, CardContent, Chip, FormControl, Grid, IconButton, InputLabel, MenuItem, Pagination, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import config from 'src/config';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import showLoading from 'src/common/loading';
import { inviteUser } from 'src/services/user.service';

// ----------------------------------------------------------------------



class UserInvitePage extends React.Component {

	constructor(props) {
		super(props);

		this.state = {

			role: localStorage.getItem('role') || config.USER_ROLES.USER,

			companies: [],

			invite_email: '',
			invite_role: null,

			users: [],
			search: '',
			limit: 10,
			skip: 0,
			total_users: 0,
		}
	}

	// promised setState
	setStateAsync(state) {
		return new Promise((resolve) => {
			this.setState(state, resolve)
		});
	}

	async componentDidMount() {
		
	}

	async inviteUser() {
		showLoading(true);
		let inviteRes = await inviteUser({
			email: this.state.invite_email,
			role: this.state.invite_role
		});
		if (inviteRes.error) {
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: inviteRes.error
			});
		} else {
			Swal.fire({
				icon: 'success',
				title: 'Success',
				text: 'User invited successfully'
			});
			this.setState({
				invite_email: '',
				invite_role: null
			});
		}
	}

	render() {
		return (
			<>
				<Helmet>
					<title> User Invite | {config.APP_NAME} </title>
				</Helmet>

				<Grid container spacing={2} mb={1} sx={{ p: 0, justifyContent: 'space-between' }}>
					<Grid item xs={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
						<Typography variant="h4">
							User Invite
						</Typography>
					</Grid>
				</Grid>

				<Grid container spacing={2} mb={1} xs={12} sm={12} md={8} lg={4} xl={3} style={{margin: 'auto'}}>
					<Grid item xs={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
						<Card variant='outlined' style={{ marginBottom: 2, width: '100%' }}>
							<CardContent style={{ padding: 10 }}>
								<Stack direction="column" spacing={2} alignItems="center">
									<TextField
										size='small'
										label={'Email'}
										variant="outlined"
										fullWidth
										value={this.state.invite_email}
										onChange={async (e) => {
											await this.setStateAsync({
												invite_email: e.target.value
											});
										}}
									/>
									<FormControl fullWidth size='small'>
										<InputLabel id="role-select-label">Role</InputLabel>
										<Select
											labelId="role-select-label"
											id="role-select"
											fullWidth
											value={this.state.invite_role}
											label="Role"
											onChange={async (e) => {
												await this.setStateAsync({
													invite_role: e.target.value
												});
											}}
										>
											{(this.state.role === config.USER_ROLES.ADMIN || this.state.role === config.USER_ROLES.SUPERADMIN) ? (
												<MenuItem value={config.USER_ROLES.ADMIN}>Admin</MenuItem>
											) : null}
											{(this.state.role === config.USER_ROLES.MANAGER || this.state.role === config.USER_ROLES.ADMIN || this.state.role === config.USER_ROLES.SUPERADMIN) ? (
												<MenuItem value={config.USER_ROLES.MANAGER}>Manager</MenuItem>
											) : null}
											<MenuItem value={config.USER_ROLES.USER}>User</MenuItem>
										</Select>
									</FormControl>
									<Button
										variant='contained'
										fullWidth
										color='primary'
										onClick={async () => {
											this.inviteUser({
												email: this.state.invite_email,
												role: this.state.invite_role
											});
										}}
									>
										Invite
									</Button>
								</Stack>
							</CardContent>
						</Card>
					</Grid>
				</Grid>

			</>
		);
	}
}

export default function (props) {
	const navigate = useNavigate();

	return <UserInvitePage {...props} navigate={navigate} />;
}
