import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardHeader, Grid } from '@mui/material';
import { getFileSubCategoryAnalytics } from 'src/services/analytics.service';
import StackedHorizontalBarChart from 'src/sections/@dashboard/app/StackedHorizontalBarChart';


const color_map = {
    'word': '#5564aa',
    'text': '#e36d26',
    'note': '#c5a88d',
    'pdf': '#f05023',
    'excel': '#2db34b',
    'powerpoint': '#f9993a',
    'archive': '#FF4560',
    'audio': '#f9993a',
    'video': '#90278f',
    'image': '#94cd81',
    'code': '#FF4560',
    'design': '#5b5b5c',
    'cad': '#888684',
    'font': '#FF4560',
    'other': '#FF4560',
}

class FileSubCategoryAnalytics extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            by_count: [],
            by_size: [],
        }
    }

    async componentDidMount() {

        let query = null;
        if (this?.props?.user_id) {
            query = {
                user_id: this.props.user_id
            };
        }
        let file_sub_category_analytics = await getFileSubCategoryAnalytics(query);
        
        if (file_sub_category_analytics.error) {
            console.error(file_sub_category_analytics.error);
            return;
        }
        file_sub_category_analytics = file_sub_category_analytics.file_sub_category_analytics;

        let by_count_array = file_sub_category_analytics.by_count || [];
        let by_size_array = file_sub_category_analytics.by_size || [];

        let by_count = [];
        let by_size = [];

        for(let i = 0; i < by_count_array.length; i++) {
            let item = by_count_array[i];
            if (i >= 10) {
                break;
            }
            by_count.push({
                name: item.file_sub_category,
                data: [item.count],
                color: color_map[item.file_sub_category] || color_map['other']
            });
        }

        for(let i = 0; i < by_size_array.length; i++) {
            let item = by_size_array[i];
            if (i >= 10) {
                break;
            }
            by_size.push({
                name: item.file_sub_category,
                data: [item.size / 1024 / 1024],
                color: color_map[item.file_sub_category] || color_map['other']
            });
        }

        this.setState({
            by_count: by_count,
            by_size: by_size
        });

    }

    render() {

        return (
            (this.state.by_count.length > 0 || this.state.by_size.length > 0) ? (
                <>
                    <Card variant='outlined'>
                        <CardHeader title='Files Sub Categories' />
                        <Grid container spacing={2}>
                            
                            <Grid item xs={12} md={6}>
                                {(this.state.by_count.length > 0) ? (
                                    <StackedHorizontalBarChart
                                        series={this.state.by_count}
                                        labels={['Count']}
                                    />
                                ) : null}
                            </Grid>
                            
                            
                            <Grid item xs={12} md={6}>
                                {(this.state.by_size.length > 0) ? (
                                    <StackedHorizontalBarChart
                                        series={this.state.by_size}
                                        labels={['Size']}
                                        round={2}
                                        unit='MB'
                                    />
                                ) : null}
                            </Grid>
                            
                        </Grid>
                    </Card>
                </>
            ) : null
        );
    }
}

// Wrap and export
export default function (props) {
    const navigate = useNavigate();
    const params = useParams();

    return <FileSubCategoryAnalytics {...props} navigate={navigate} params={params} />;
}
