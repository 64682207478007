import ReactApexChart from 'react-apexcharts';
import React from 'react';

// ----------------------------------------------------------------------

export default class StackedHorizontalBarChart extends React.Component {
    constructor(props) {
      super(props);
    }

    render() {

      let options = {
        chart: {
          type: 'bar',
          stacked: true,
          toolbar: {
              show: false
          }
        },
        colors: this.props.series.map((item, index) => { return item.color }),
        plotOptions: {
          bar: {
            borderRadius: 5,
            borderRadiusApplication: 'around', // 'around', 'end'
            borderRadiusWhenStacked: 'all', // 'all', 'last'
            horizontal: true,
            barHeight: '80%',
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 1,
          colors: ["#fff"]
        },
        
        grid: {
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        tooltip: {
          shared: false,
          x: {
            formatter: function (val) {
              return val
            }
          },
          y: {
            formatter: (val) => {
              if (this.props?.round) {
                return Math.abs(val).toFixed(this.props.round) + (this.props?.type === 'percentage' ? '%' : (this.props?.unit ? ' ' + this.props.unit : ''))
              }
              return Math.abs(val) + (this.props?.type === 'percentage' ? '%' : (this.props?.unit ? ' ' + this.props.unit : ''))
            }
          }
        },
        xaxis: {
          categories: this.props.labels ? this.props.labels : [],
          labels: {
            formatter: (val) => {
              return Math.abs(Math.round(val)) + (this.props?.type === 'percentage' ? '%' : '')
            }
          }
        },
        yaxis: (this.props?.type === 'percentage') ? {
          min: 0,
          max: 100,
          // stepSize: 1
        } : undefined,
      }

      let series = this.props.series || [];

      if (!series?.length) {
        return null;
      }

      return (
            <>
                <ReactApexChart options={options} series={series} type="bar" height={150} />
            </>
      );
    }
  }