import React from 'react';
import { Button, Grid, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { connectOnedrive, getOnedriveDetails, syncOnedrive, unsyncOnedrive, updateOnedriveDetails } from 'src/services/onedrive.service';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated } from '@azure/msal-react';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from 'src/config/msal.config';
import Swal from 'sweetalert2';
import SyncIcon from '@mui/icons-material/Sync';
import { ReactWebStore } from 'react-webstore';


// import image
import OnedriveLogo from 'src/assets/storageIcons/onedrive.png';
import OnedriveLogoDisconnected from 'src/assets/storageIcons/onedrive_disconnected.png';
import ArrayInput from 'src/components/array-input';
import { updateStorageEmail } from 'src/services/storage.service';
import config from 'src/config';

// ----------------------------------------------------------------------

class OnedriveStatusCheckAndConnect extends React.Component {
  
  constructor(props) {
    super(props);

    this.rws = new ReactWebStore();
    this.state = {
      connected: false,
      syncing: false,
      unsyncing: false,
      sharepoint_site_ids: [],
    }

  }

  async componentDidMount() {

    let onedriveDetailsRes = await getOnedriveDetails();
    if (onedriveDetailsRes.error) {
      console.log('onedriveDetailsRes.error', onedriveDetailsRes.error);
    } else {
      this.setState({
        sharepoint_site_ids: onedriveDetailsRes?.onedrive?.sharepoint_site_ids || []
      });
    }

  }



  connectOnedrive = async () => {
      // instance.loginPopup(loginRequest).catch(e => {
      //     console.log(e);
      // });
      
      this.props.instance.loginRedirect(loginRequest).catch(e => {
          console.error(e);
      });
  }

  connectAndUpdateOnedriveDetails = async () => {

    try {
      console.log('updateOnedriveDetails');

      const instance = this.props.instance;
      const accounts = this.props.accounts;


      return new Promise((resolve, reject) => {
        instance
          .acquireTokenSilent({
              ...loginRequest,
              account: accounts[0],
          })
          .then(async (response) => {

              console.log('response', response);

              // send to server
              let connectOnedriveRes = await connectOnedrive(response);
              console.log('connectOnedriveRes', connectOnedriveRes)
              if (connectOnedriveRes.error) {
                console.log('connectOnedriveRes.error', connectOnedriveRes.error);
                alert('Error connecting Onedrive');
                window.history.pushState({}, document.title, window.location.pathname);  
                resolve();
              } else {
                this.setState({
                  connected: true
                });
                window.history.pushState({}, document.title, window.location.pathname);
                resolve();
              }
          });
      });
    } catch (error) {
      console.error('error', error);
      alert('Error connecting Onedrive');
    }

    
  }

  syncFiles = async () => {

    this.setState({
      syncing: true
    });

    console.log("syncing files");

    await this.connectAndUpdateOnedriveDetails();

    let syncOnedriveRes = await syncOnedrive();
    if (syncOnedriveRes.error) {
      this.setState({
        syncing: false
      });
      console.log('syncOnedriveRes.error', syncOnedriveRes.error);
      this.rws.store('sync_files', 'failed');
      alert('Error syncing Onedrive');
    } else {
      this.setState({
        syncing: false
      });
      this.rws.store('sync_files', 'success');
      if (syncOnedriveRes.message_code == 'syncing_in_background') {
        alert('Onedrive files syncing in background. It may take some time. We will send a mail when its done');
      } else {
        alert(syncOnedriveRes.message ? syncOnedriveRes.message : 'Onedrive synced successfully');
      }
    }
  }

  unsyncFiles = async () => {

    let confirm = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to unsync Onedrive? All the labels and containers will be removed and the files will be deleted from dMonk server. The files will remain in your Onedrive account.',
      input: 'text',
      inputPlaceholder: 'Type "delete onedrive data" to confirm',
      inputValidator: (value) => {
        if (value !== 'delete onedrive data') {
          return 'You need to type "delete onedrive data" to confirm';
        }
      },
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Unsync it!',
      confirmButtonColor: 'red',
      cancelButtonText: 'No, keep it'
    });

    if (!confirm?.isConfirmed) {
      return;
    }

    if (confirm.value !== 'delete onedrive data') {
      Swal.fire('Error!', 'You need to type "delete onedrive data" to confirm', 'error');
      return;
    }

    this.setState({
      unsyncing: true
    });

    let unsyncOnedriveRes = await unsyncOnedrive();
    if (unsyncOnedriveRes.error) {
      this.setState({
        unsyncing: false
      });
      console.log('unsyncOnedriveRes.error', unsyncOnedriveRes.error);
      Swal.fire('Error!', 'Error unsyncing Onedrive', 'error');
    } else {
      this.setState({
        unsyncing: false
      });
      console.log('unsyncOnedriveRes.success', unsyncOnedriveRes.success);
      Swal.fire('Unsynced!', 'Onedrive has been unsynced.', 'success');
    }
  }

  updateOnedriveDetails = async () => {

    let payload = {
      sharepoint_site_ids: this.state.sharepoint_site_ids
    };

    let updateOnedriveDetailsRes = await updateOnedriveDetails(payload);
    if (updateOnedriveDetailsRes.error) {
      console.log('updateOnedriveDetailsRes.error', updateOnedriveDetailsRes.error);
      alert('Error updating');
    } else {
      console.log('updateOnedriveDetailsRes.success', updateOnedriveDetailsRes.success);
      alert('Update successful');
    }
  }

  
  render() {

    if (this.props?.accounts?.[0]?.username) {
      updateStorageEmail(config.FILE_SERVICES.ONEDRIVE, this.props?.accounts?.[0]?.username);
    }

    return (
      <Grid>

            <AuthenticatedTemplate>
                {(this.props.minimal) ? (
                    <IconButton aria-label="connected"
                        onClick={this.syncFiles}
                    >
                        {(this.state.syncing) ? (
                          <SyncIcon style={{color: 'gray', animation: 'spin 2s linear infinite reverse'}} />
                        ) : (
                          <img src={OnedriveLogo} style={{width: '25px', height: 'auto'}} />
                        )}
                    </IconButton>
                ) : (
                    <>

                        {/* Get the account name */}
                        {this.props.accounts[0] && (this.props.accounts[0].name || this.props.accounts[0].username) ? (
                            <>
                              <p>Connected as <b>{this.props?.accounts?.[0]?.name || ''}</b> with username <b>{this.props?.accounts?.[0]?.username || ''}</b></p>
                            </>
                        ) : null}

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.connectOnedrive}
                            disabled
                            sx={{ mr: 2 }}
                        >
                            Connected
                        </Button>

                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={this.connectOnedrive}
                            sx={{ mr: 2 }}
                        >
                            Re-Connect
                        </Button>
                    </>
                )}

                {(this.props.minimal) ? (
                    null
                ) : (
                    <Button
                    variant="contained"
                    color="secondary"
                    onClick={this.syncFiles}
                    disabled={this.state.syncing}
                    sx={{ mr: 2 }}
                    >
                    {this.state.syncing ? 'Syncing' : 'Sync'}
                    </Button>
                )}

                {(this.props.minimal) ? (
                    null
                ) : (
                    <Button
                    variant="outlined"
                    color="error"
                    onClick={this.unsyncFiles}
                    disabled={this.state.unsyncing}
                    >
                    {this.state.unsyncing ? 'Un-Syncing' : 'Un-Sync'}
                    </Button>
                )}





                {(this.props.minimal) ? (
                    null
                ) : (
                  <>
                    <ArrayInput
                      title="Sharepoint Site IDs"
                      buttonText="Add Site ID"
                      placeholderText="Enter Site ID"
                      allowOrdering={false}
                      value={this.state.sharepoint_site_ids}
                      onChange={(sharepoint_site_ids) => {
                        this.setState({
                          sharepoint_site_ids: sharepoint_site_ids
                        });
                      }}
                      style={{marginTop: '20px'}}
                    />

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.updateOnedriveDetails}
                      disabled={this.state.syncing}
                      sx={{ mt: 2 }}
                    >
                      Update Onedrive Details
                    </Button>
                  </>
                )}



            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>

                {(this.props.minimal) ? (
                    <IconButton aria-label="connected"
                        onClick={this.connectOnedrive}
                    >
                        <img src={OnedriveLogoDisconnected} style={{width: '25px', height: 'auto'}} />
                    </IconButton>
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.connectOnedrive}
                        sx={{ mr: 2 }}
                    >
                        Connect Onedrive
                    </Button>
                )}

                {(this.props.hideUnsyncButton) ? null : (
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={this.unsyncFiles}
                        disabled={this.state.unsyncing}
                    >
                        {this.state.unsyncing ? 'Un-Syncing' : 'Un-Sync'}
                    </Button>
                )}

            </UnauthenticatedTemplate>
         
      </Grid>
    );
  }
}

export default function (props) {
    const navigate = useNavigate();

    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    return <OnedriveStatusCheckAndConnect {...props} navigate={navigate} isAuthenticated={isAuthenticated} instance={instance} accounts={accounts} />;
}
