// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, IconButton, Tooltip, Typography } from '@mui/material';
// utils
import { fData, fShortenNumber } from '../../../utils/formatNumber';
// components
import InfoIcon from '@mui/icons-material/Info';

// ----------------------------------------------------------------------

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

export default function AppWidgetSummary({ title, total, count, owned, shared, icon, color = 'primary', sx, ...other }) {
  return (
    <Card
      sx={{
        py: 2,
        boxShadow: 0,
        textAlign: 'center',
        alignItems: 'center',
        color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => theme.palette[color].lighter,
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        height: '100%',
        ...sx,
      }}
      {...other}
    >

      <div>
        {icon}
      </div>
      <div>

        <Typography variant="h3">
          {String(fData(total)).toUpperCase()}
          {(owned || shared) && (
            <Tooltip title={
              <>
                {(owned) && (
                  <Typography variant="body2">
                    {`Owned: ${fData(owned)}`}
                  </Typography>
                )}
                {(shared) && (
                  <Typography variant="body2">
                    {`Shared: ${fData(shared)}`}
                  </Typography>
                )}
              </>
            }>
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          )}
        </Typography>

        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
          {count + ' files'} 
        </Typography>

      </div>


    </Card>
  );
}
