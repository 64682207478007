import axios from './axios.service';
import config from './../config';
import { getQueryStringFromObject, getRequestHeaders } from './common.service';

async function getCompanies(query=null) {
  try {
    let queryString = '';
    if (query) {
        queryString = '?' + getQueryStringFromObject(query);
    }
    let response;
    try {
        let headers = getRequestHeaders();
        response = await axios.get( config.SERVER_BASE_URL + '/companies' + queryString, { headers: headers });
    } catch (error) {
        return error.response.data;
    }
    return response.data;
  } catch (error) {
      return { error: error.message };
  }
}

async function getCompany(companyId) {
    let response;
    try {
        let headers = getRequestHeaders();
        response = await axios.get( config.SERVER_BASE_URL + '/companies/' + companyId, { headers: headers });
    } catch (error) {
        return error.response.data;
    }
    return response.data;
}

async function updateCompany(companyId, payload) {
  let response;
  try {
      let headers = getRequestHeaders();
      response = await axios.patch( config.SERVER_BASE_URL + '/companies/' + companyId, payload, { headers: headers });
  } catch (error) {
      return error.response.data;
  }
  return response.data;
}

async function addCompany(payload) {
  let response;
  try {
      let headers = getRequestHeaders();
      response = await axios.post( config.SERVER_BASE_URL + '/companies', payload, { headers: headers });
  } catch (error) {
      return error.response.data;
  }
  return response.data;
}

export {
    getCompanies,
    getCompany,
    updateCompany,
    addCompany
}
