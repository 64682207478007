import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import LabelIcon from '@mui/icons-material/Label';

import Swal from "sweetalert2";

import { Autocomplete, Backdrop, Button, Card, Checkbox, Fade, FormControlLabel, FormGroup, FormHelperText, Grid, Modal, Stack, TextField } from "@mui/material";

import config from "../config";
import { updateFlagsToMultipleFiles, updateLabelsToMultipleFiles } from '../services/file.service';
import showLoading from "src/common/loading";

const flags = [
	{ value: 'attachment', label: 'Attachment', color: 'purple' },
	{ value: 'important', label: 'Important', color: 'red' },
	{ value: 'in_house', label: 'In house', color: 'blue' },
	{ value: 'temp', label: 'Temp', color: 'black' },
	{ value: 'wip', label: 'WIP', color: 'orange' },
	{ value: 'complete', label: 'Complete', color: 'green' },
	{ value: 'final', label: 'Final', color: 'red'},
	{ value: 'archive', label: 'Archive', color: 'grey'},
]

class AddLabelOrFlagModal extends React.Component {

	constructor(props) {
		super(props);
		this.state = {

			mode: props?.mode ? props.mode : 'label',
			select_all: props?.select_all ? props.select_all : false,
			query: props?.query ? props.query : {},
			total_files: props?.total_files ? props.total_files : 0,
            file_ids: props?.file_ids ? props.file_ids : [],

			flags: [],
			labels: [],
			operation: "append", // append, replace
			
			loading: false,
			error: {},
		}
	}

	// promised setState
	setStateAsync(state) {
		return new Promise((resolve) => {
			this.setState(state, resolve)
		});
	}

	async componentDidMount() {
		
	}

	saveLabel = async () => {

		this.setState({
			loading: true
		});
		showLoading(true);

		let payload = {
			labels: this.state.labels,
			operation: this.state.operation
		}

		if (this.state.select_all) {
			payload.select_all = true;
			payload.query = this.state.query;
			payload.total_files = this.state.total_files;
		} else {
			payload.file_ids = this.state.file_ids;
		}

		let save_response = await updateLabelsToMultipleFiles(payload);

		if (save_response.error) {

			showLoading(false);

			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: save_response.error,
				confirmButtonText: 'Ok',
				confirmButtonColor: config.primaryColor
			});
			this.setState({
				loading: false
			});
			return;
		}

		showLoading(false);
		this.setState({
			loading: false
		});

		Swal.fire({
			icon: 'success',
			title: 'Success',
			text: 'Saved successfully',
			confirmButtonText: 'Ok',
			confirmButtonColor: config.primaryColor
		});
		this.props.onSave();

	}

	saveFlag = async () => {

		this.setState({
			loading: true
		});
		showLoading(true);

		let payload = {
			flags: this.state.flags,
			operation: this.state.operation
		}

		if (this.state.select_all) {
			payload.select_all = true;
			payload.query = this.state.query;
			payload.total_files = this.state.total_files;
		} else {
			payload.file_ids = this.state.file_ids;
		}

		let save_response = await updateFlagsToMultipleFiles(payload);
		
		if (save_response.error) {

			showLoading(false);

			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: save_response.error,
				confirmButtonText: 'Ok',
				confirmButtonColor: config.primaryColor
			});
			this.setState({
				loading: false
			});
			return;
		}

		showLoading(false);
		this.setState({
			loading: false
		});

		Swal.fire({
			icon: 'success',
			title: 'Success',
			text: 'Saved successfully',
			confirmButtonText: 'Ok',
			confirmButtonColor: config.primaryColor
		});
		this.props.onSave();

	}

	saveLabelOrFlag = async () => {

		if (this.props?.mode == 'label') {
			this.saveLabel();
		} else {
			this.saveFlag();
		}

	}

	// generateLabels = async () => {
	// 	try {

	// 		await this.setStateAsync({
	// 			generating_labels: true
	// 		});

	// 		let labels_response = await generateLabels(this.state.file_id);
	// 		if (labels_response.error) {
	// 			console.log("Error generating labels:", labels_response.error);
	// 			alert("Error generating labels - " + labels_response.error)
	// 			await this.setStateAsync({
	// 				generating_labels: false
	// 			});
	// 			return;
	// 		}

	// 		labels_response = labels_response.labels;
	// 		labels_response = JSON.parse(labels_response);

	// 		console.log("labels_response", labels_response)

	// 		let labels = this.state.labels;
	// 		labels = labels.concat(labels_response.Keywords);

	// 		// unique labels
	// 		labels = [...new Set(labels)];

	// 		await this.setStateAsync({
	// 			generating_labels: false,
	// 			labels: labels
	// 		});
	// 	} catch (error) {
	// 		await this.setStateAsync({
	// 			generating_labels: false
	// 		});
	// 		alert("Unknown error occurred while generating labels - " + error.message)
	// 		console.log("Unknown error occurred while generating labels - ", error.message)
	// 	}
	// }

	render() {
		return (
			<div>
				<Modal
					aria-labelledby="transition-modal-title"
					aria-describedby="transition-modal-description"
					open={this.props.open}
					onClose={() => {

						if (this.props.onClose)
							this.props.onClose();
					}}
					closeAfterTransition
					slots={{ backdrop: Backdrop }}
					slotProps={{
						backdrop: {
							timeout: 500,
						},
					}}
				>
					<Fade in={this.props.open}>
						<Card sx={modalStyle}>
							<Grid container spacing={2} mb={2} sx={{ p: 1 }}>
								{this.state.mode === 'flag' ? (
									<Grid item xs={12}>
										<Stack spacing={1}>
											<FormGroup sx={{flexDirection: 'row'}} >
												{flags.map((flag, index) => {
													return (
														<FormControlLabel
															key={index}
															control={
																<Checkbox
																	checked={this.state.flags.includes(flag.value)}
																	sx={{
																		color: flag.color,
																		'&.Mui-checked': {
																			color: flag.color,
																		},
																	}}
																/>
															}
															onChange={(e) => {
																if (e.target.checked) {
																	this.setState({
																		flags: [...this.state.flags, flag.value]
																	});
																} else {
																	this.setState({
																		flags: this.state.flags.filter(f => f !== flag.value)
																	});
																}
															}}
															label={flag.label}
														/>
													)
												})}
											</FormGroup>
										</Stack>
									</Grid>
								) : null}
								{(this.state.mode === 'label') ? (
									<Grid item xs={12}>
										<Stack spacing={1} direction={'row'}>
											<Autocomplete
												freeSolo
												multiple
												id="labels-outlined"
												options={[]}
												value={this.state.labels}
												onChange={(event, newValue) => {
													this.setState({
														labels: newValue
													});
												}}
												renderInput={(params) => (
													<TextField
														{...params}
														variant="outlined"
														label="Labels"
														placeholder="What is it ?"
														InputProps={{
															...params.InputProps,
															endAdornment: (
																<React.Fragment>
																	{params.InputProps.endAdornment}
																	<LabelIcon />
																</React.Fragment>
															)
														}}
													/>
												)}
												sx={{ width: '100%' }}
												size="small"
											/>
											{/* <Button variant="outlined" size="small"
												sx={{textTransform: 'none', width: '100%', maxWidth: 'fit-content'}}
												disabled={this.state.generating_labels}
												onClick={this.generateLabels}
												startIcon={
													(this.state.generating_labels) ? (
														<SyncIcon style={{color: 'gray', animation: 'spin 2s linear infinite reverse'}} />
													) : null
												}
											>
												{(this.state.generating_labels) ? ' Generating labels...' : 'Generate labels'}
											</Button> */}
										</Stack>
									</Grid>
								) : null}

								<Grid item xs={12}>
									<Stack direction={'row'}>
										<FormControlLabel
											control={
												<Checkbox
													checked={this.state.operation == 'append'}
													onChange={(e) => {
														this.setState({
															operation: 'append'
														});
													}}
												/>
											}
											label="Append"
											helpertext="Append new labels to existing labels"
										/>
										<FormControlLabel
											control={
												<Checkbox
													checked={this.state.operation == 'replace'}
													onChange={(e) => {
														this.setState({
															operation: 'replace'
														});
													}}
												/>
											}
											label="Replace"
											helpertext="Replace existing labels with new labels"
										/>
									</Stack>
									{(this.state.mode === 'label') ? (
										<FormHelperText>
											{this.state.operation == 'append' ? 'Append new labels to existing labels' : 'Replace existing labels with new labels. Note: Not giving any labels will remove all existing labels.'}
										</FormHelperText>
									) : null}
									{(this.state.mode === 'flag') ? (
										<FormHelperText>
											{this.state.operation == 'append' ? 'Append new flags to existing flags' : 'Replace existing flags with new flags. Note: Not giving any flags will remove all existing flags.'}
										</FormHelperText>
									) : null}
								</Grid>

							</Grid>

							<Grid container spacing={2} mb={2} sx={{ p: 1, justifyContent: 'space-around' }}>
								<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
									<Button
										disableElevation
										fullWidth
										variant="contained"
										onClick={this.saveLabelOrFlag}
										disabled={this.state.loading}
										size="small"
									>
										{this.state.loading ? 'Saving...' : 'Save'}
									</Button>
								</Grid>
							</Grid>


						</Card>
					</Fade>
				</Modal>
			</div>
		);
	}
}

const modalStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '80%',
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 2,
	maxHeight: '90%',
	overflowY: 'auto'
};

// Wrap and export
export default function (props) {
	const params = useParams();
	const navigate = useNavigate();
	return <AddLabelOrFlagModal {...props} navigate={navigate} params={params} />;
}