import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { getOrgSizeAnalytics } from 'src/services/analytics.service';
import { AppWidgetSummary } from 'src/sections/@dashboard/app';
import StorageIcon from '@mui/icons-material/Storage';

import GoogleDriveLogo from 'src/assets/storageIcons/googledrive.png';
import DropboxLogo from 'src/assets/storageIcons/dropbox.png';
import OneDriveLogo from 'src/assets/storageIcons/onedrive.png';

const title_icon_map = {
    "dropbox": {
        title: "Dropbox",
        icon: <img src={DropboxLogo} style={{width: '45px', height: 'auto'}} />
    },
    "onedrive": {
        title: "OneDrive",
        icon: <img src={OneDriveLogo} style={{width: '45px', height: 'auto'}} />
    },
    "googledrive": {
        title: "Google Drive",
        icon: <img src={GoogleDriveLogo} style={{width: '45px', height: 'auto'}} />
    }
};

class OrgSizeAnalytics extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            sizeAnalytics: {
                total_size: 0,
                total_count: 0,
                services: []
            }
        }
    }

    async componentDidMount() {

        let size_analytics = await getOrgSizeAnalytics();
        
        if (size_analytics.error) {
            console.error(size_analytics.error);
            return;
        }
        size_analytics = size_analytics.size_analytics;

        this.setState({
            sizeAnalytics: size_analytics
        });

    }

    render() {

        return (
            <Grid container spacing={2}>

                {(this.state.sizeAnalytics.services.map((service, index) => {
                    return (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                            <AppWidgetSummary
                                title={title_icon_map[service.service].title}
                                total={service.size}
                                count={service.count}
                                owned={service?.owned?.size ? service.owned.size : null}
                                shared={service?.shared?.size ? service.shared.size : null}
                                icon={title_icon_map[service.service].icon}
                            />
                        </Grid>
                    );
                }))}
                {(this.state.sizeAnalytics.total_size) ? (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <AppWidgetSummary
                            title="Total"
                            color="error"
                            total={this.state.sizeAnalytics.total_size}
                            count={this.state.sizeAnalytics.total_count}
                            icon={<StorageIcon style={{width: '45px', height: 'auto'}} />}
                        />
                    </Grid>
                ) : null}
            </Grid>
        );
    }
}

// Wrap and export
export default function (props) {
    const navigate = useNavigate();
    const params = useParams();

    return <OrgSizeAnalytics {...props} navigate={navigate} params={params} />;
}
