import axios from './axios.service';
import config from './../config';
import { getQueryStringFromObject, getRequestHeaders } from './common.service';

async function getUsers(query=null) {
  try {
    let queryString = '';
    if (query) {
        queryString = '?' + getQueryStringFromObject(query);
    }
    let response;
    try {
        let headers = getRequestHeaders();
        response = await axios.get( config.SERVER_BASE_URL + '/users' + queryString, { headers: headers });
    } catch (error) {
        return error.response.data;
    }
    return response.data;
  } catch (error) {
      return { error: error.message };
  }
}

async function getUser(userId) {
    let response;
    try {
        let headers = getRequestHeaders();
        response = await axios.get( config.SERVER_BASE_URL + '/users/' + userId, { headers: headers });
    } catch (error) {
        return error.response.data;
    }
    return response.data;
}

async function updateUser(userId, payload) {
  let response;
  try {
      let headers = getRequestHeaders();
      response = await axios.patch( config.SERVER_BASE_URL + '/users/' + userId, payload, { headers: headers });
  } catch (error) {
      return error.response.data;
  }
  return response.data;
}

async function inviteUser(payload) {
    let response;
    try {
        let headers = getRequestHeaders();
        response = await axios.post( config.SERVER_BASE_URL + '/users/invite', payload, { headers: headers });
    } catch (error) {
        return error.response.data;
    }
    return response.data;
}

export {
    getUsers,
    getUser,
    updateUser,
    inviteUser,
}
