export default {
    APP_NAME: 'dMonk',
    APP_VERSION: '0.1.18',
    APP_MODE: process.env.NODE_ENV || 'development',
    SERVER_BASE_URL: process.env.REACT_APP_BASE_URL,
    DROPBOX_CALLBACK_URL: process.env.REACT_APP_DROPBOX_CALLBACK_URL,
    DROPBOX_APP_KEY: process.env.REACT_APP_DROPBOX_APP_KEY,
    FILE_SERVICES: {
        DROPBOX: 'dropbox',
        ONEDRIVE: 'onedrive',
        GOOGLEDRIVE: 'googledrive'
    },
    USER_ROLES: {
        SUPERADMIN: 'superadmin',
        ADMIN: 'admin',
        MANAGER: 'manager',
        USER: 'user'
    },
    USER_ACCOUNT_TYPES: {
        PERSONAL: 'personal',
        BUSINESS: 'business'
    }
}
